<template>
  <div class="box">
    <router-link
      v-if="$can('admin/flavor/create')"
      :to="{ path: '/flavor/create' }"
    >
      <el-button
        type="primary"
        size="medium"
      >
        新建属性
      </el-button>
    </router-link>
    <div class="box-toolbar">

      <div class="box-item">
        <el-input
          placeholder="规格组名称"
          v-model="flavorName"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>

    <el-table
      border
      v-loading="loading"
      :stripe="true"
      :data="lists"
      height="75vh"
    >
      <el-table-column
        width="80"
        label="序号"
        prop="id"
      >
      </el-table-column>
      <el-table-column
        label="规格组名称"
        prop="flavorName"
      >
      </el-table-column>
      <el-table-column
        label="是否必选"
        prop="isRequired"
      >
      </el-table-column>
      <el-table-column
        label="是否参与打折"
        prop="isSales"
      >
      </el-table-column>
      <el-table-column
        label="是否单选"
        prop="choiceMode"
      >
      </el-table-column>
      <el-table-column
        width="100"
        label="操作"
      >
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <el-button
              plain
              size="mini"
              type="primary"
            >
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="$can('admin/flavor/update')"
                @click.native="handleClassifyEdit(scope.row)"
              >
                编辑
              </el-dropdown-item>
              <el-dropdown-item
                v-if="$can('admin/flavor/delete')"
                @click.native="handleClassifyDelete(scope.row)"
              >
                删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <page
      :pageSize="limit"
      :count="count"
      @change="pageChange"
      @changeSize="changeSize"
    >
    </page>
  </div>
</template>

<script>
import FlavorService from '@admin/services/FlavorService'
import flatry from '@admin/utils/flatry'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'

export default {
  components: {
    Page
  },
  data () {
    return {
      loading: true,
      function: function (param) {
        return FlavorService.all(param)
      },
      flavorName: ''
    }
  },
  mixins: [pagination],

  methods: {
    handleClassifyEdit (Classifys) {
      this.$router.push({
        path: '/flavor/update',
        query: { id: Classifys.id }
      })
    },
    handleClassifyDelete (flavor) {
      this.$deleteDialog(
        `商品规格 ${flavor.flavorName}`,
        async action => {
          if (action === 'confirm') {
            this.loading = true

            const { data } = await flatry(
              FlavorService.delete(flavor.id)
            )

            if (data) {
              this.lists.forEach((item, index) => {
                if (item.id === flavor.id) {
                  this.lists.splice(index, 1)
                }
              })

              this.$message({
                type: 'success',
                message: data.msg
              })
            }

            this.loading = false
          }
        }
      )
    },
    async searchGo () {
      this.page = 1
      let param = {
        flavorName: this.flavorName
      }
      this.where = param
      await this.getList(param)
    }
  }
}
</script>

<style scoped>
</style>
